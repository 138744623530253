<template>
	<slot v-if="modelId" v-bind="modelValueSlot[modelId]"></slot>
	<template v-else>
		<BlViewFields v-for="id in getIds()" :key="id" :name="name" :modelId="id" :shortLabel="shortLabel">
			<slot :get-field="getField(id)" v-bind="modelValueSlot[id]"></slot>
		</BlViewFields>
	</template>
</template>

<script>
export default {
	name: 'BlViewFields',
	props: {
		name: {
			type: String
		},
		modelId: {
			type: Number,
			default: null
		},
		shortLabel: {
			type: Boolean,
			default: null
		}
	},
	inject: ['blViewAddField', 'blViewGetField', 'blViewGetName', 'blViewGetData', 'blViewUpdaded', 'blViewFieldsShortLabel'],
	data() {
		return {
			modelValueSlot: {}
		}
	},
	provide() {
		return {
			blViewGetName: () => this.modelId ? this.getName(this.blViewGetName()) : this.blViewGetName(),
			blViewAddField: field => {
				if(!this.name) return
				else return this.modelId ? this.blViewAddField({name: this.getName() + '.' + field.name, formatted: field.formatted, shortLabel: field.shortLabel}) : this.blViewAddField({name: field.name, formatted: field.formatted, shortLabel: field.shortLabel})
			},
			blViewGetField: name => {
				if(!this.name) return
				return this.modelId ? this.blViewGetField(this.getName() + '.' + name) : this.blViewGetField(name)
			},
			blViewFieldsShortLabel: () => this.getShortLabel()
		}
	},
	created() {
		if(!this.modelId) this.blViewAddField({name: this.getName() + '.id', formatted: true, shortLabel: this.getShortLabel()})
		this.blViewGetData()
		this.blViewUpdaded().subscribe(() => {
			for(let id of this.modelId ? (this.modelId) : this.getIds()) {
				this.modelValueSlot[id] = {}
				let lineData = {}
				for(let key in this.blViewGetData()) {
					let subKey = this.blViewGetName() + this.name + '[' + id + '].'
					if(key.substring(0, subKey.length) == subKey) lineData[key.substring(subKey.length)] = this.blViewGetData()[key].value
				}
				this.modelValueSlot[id] = lineData
			}
		})
	},
	methods: {
		getName(parent) {
			return (parent ? (parent + '.') : '') + this.name + '[' + (this.modelId ? this.modelId : '*') + ']'
		},
		getIds() {
			let propName = (this.blViewGetName() ? (this.blViewGetName() + '.') : '') + this.name
			let ids = []
			for(let key in this.blViewGetData()) {
				if(key.substr(0, propName.length) == propName) {
					let id = key.substr(propName.length + 1).split(']')[0]
					if(!ids.includes(id) && id) ids.push(id)
				}
			}
			return ids
		},
		getField(id) {
			return name => {
				if(!this.name) return
				name = this.blViewGetName() + this.name + '[' + id + '].' + name
				this.blViewAddField({name: name, formatted: true})
				let value = this.blViewGetField(name)
				return value ? value.value : null
			}
		},
		getShortLabel() {
			return this.shortLabel !== null ? this.shortLabel : (this.blViewFieldsShortLabel ? this.blViewFieldsShortLabel() : null)
		}
	}
}
</script>

<style scoped lang="scss">
</style>