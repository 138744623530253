<template>
	<button class="bl-icon-button" @click="close()" style="float: right;">close</button>
	<BlTabs>
		<BlTab>
			<BlTabHeader>
				<i class="material-icons">tune</i>
				Options
			</BlTabHeader>
			<BlTabBody>
				<ul>
					<li v-for="option in options" :key="option.name">
						<div class="header">
							<input class="bl-checkbox" type="checkbox" :checked="isEnabled(option)" @click="toggleOption(option)" />
							<h4 @click="toggleOption(option)">{{ parseName(option.name) }}</h4>
							<span class="required" v-if="option.required">Required</span>
							<span class="default" v-if="option.default !== null">Default <b>{{ option.default }}</b></span>
						</div>

						<p v-if="option.description">{{ option.description }}</p>

						<div v-bl-input :class="{errored: valueErrored[option.name]}" v-if="isEnabled(option)">
							<input type="text" :ref="'optionField' + option.name" v-model="valueText[option.name]" @change="bindOption(option)" @keydown.enter="bindOption(option); save();" />
						</div>
					</li>
				</ul>
			</BlTabBody>
		</BlTab>
		<BlTab>
			<BlTabHeader>
				<i class="material-icons">code</i>
				JSON
			</BlTabHeader>
			<BlTabBody>
				<div style="min-width: 780px;">
					<BlFormFieldRichtext v-if="showField" :field="jsonField" />
				</div>
			</BlTabBody>
		</BlTab>
	</BlTabs>
	<div class="actionsContainer">
		<BlButton label="Save" @click="save()" />
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { Dialog, EventEmitter } from 'InterfaceBundle'

export default {
	name: 'BlFormFieldModeloptionselectorDialog',
	props: ['model', 'fieldOptions', 'property'],
	data() {
		return {
			value: null,
			valueText: null,
			valueErrored: {},
			options: [],
			jsonFieldErrored: false,
			/**
			 * Json field options
			 * @type {Object}
			 */
			jsonField: {
				value: '',
				isErrored: () => this.jsonFieldErrored,
				getTouched: () => true,
				setTouched: () => false,
				setValue: v => {
					this.jsonFieldErrored = false
					try {
						this.value = JSON.parse(v)
						for(let k in this.value) this.valueText[k] = JSON.stringify(this.value[k])
					} catch(e) {
						this.jsonFieldErrored = true
						this.value = {}
					}
				},
				label: 'JSON',
				emitter: {
					change: new EventEmitter(),
					focus: new EventEmitter(),
					beforeSubmit: new EventEmitter(),
					beforeValidation: new EventEmitter(),
					dataChange: new EventEmitter()
				},
				options: {
					code: {
						mode: 'json',
						height: 20,
						native: {showLineNumbers: false, showGutter: false, showPrintMargin: false}
					}
				}
			},
			showField: false
		}
	},
	created() {
		this.value = this.model ? JSON.parse(JSON.stringify(this.model)) : {}
		this.setValue()
		const arg1 = this.fieldOptions.type ? '"' + this.fieldOptions.type + '"' : 'null'
		const arg2 = this.property == 'form' && !this.fieldOptions.type ? '"' + this.fieldOptions.modelname + '|' + this.fieldOptions.propertyname + '"' : 'null'

		let req = {}
		req['context("options"):script.run("BlFormFieldModeloptionselectorDialog", "' + this.property + '", ' + arg1 + ', ' + arg2 + ')'] = {
			'loop("option"):local.options': {
				value: 'local.option.getValue()',
				name: 'local.option.getName()',
				default: 'local.option.getDefault()',
				required: 'local.option.getRequired()',
				description: 'local.option.getDescription()'
			}
		}

		Api.post('api/structure/', req).then(resp => this.options = resp.filter(o => o.description))

		document.addEventListener('keydown', this.handleKeyboardShortcuts)
	},
	unmounted() {
		document.removeEventListener('keydown', this.handleKeyboardShortcuts)
	},
	methods: {
		parseName(name) {
			let ret = name.replaceAll('_', ' ').replace(/([A-Z])/g, ' $1').toLowerCase()
			return ret.replace(ret[0], ret[0].toUpperCase())
		},
		save() {
			console.log(this.value)
			Dialog.close(true, true, this.value)
		},
		isEnabled(option) {
			return typeof this.value[option.name] !== 'undefined' && typeof this.value[option.name] !== 'function'
		},
		toggleOption(option) {
			if(this.isEnabled(option)) delete this.value[option.name]
			else {
				this.value[option.name] = option.default
				this.$nextTick(() => {
					this.$refs['optionField' + option.name].select()
				})
			}
			this.setValue()
		},
		close() {
			Dialog.close(false)
		},
		bindOption(option) {
			this.valueErrored[option.name] = false;
			try {
				this.value[option.name] = JSON.parse(this.valueText[option.name])
			} catch(e) {
				this.valueErrored[option.name] = true;
			}
			this.setValue()
		},
		setValue() {
			this.showField = false
			this.jsonField.value = JSON.stringify(Object.assign({}, this.value), null, '\t')
			this.$nextTick(() => this.showField = true)
			this.valueText = {}
			for(let key in this.value) this.valueText[key] = JSON.stringify(this.value[key])
		},
		handleKeyboardShortcuts(event) {
			if(event.key == 's' && event.ctrlKey && !this.currentRequest) {
				event.preventDefault()
				event.stopPropagation()
				document.activeElement.blur()
				this.$nextTick(() => this.save())
				return false
			}
		}
	}
}
</script>

<style scoped lang="scss">
	.noData {
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		img {
			max-width: 150px;
			margin-bottom: 15px;
		}
	}

	.actionsContainer {
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
	}

	ul {
		list-style: none;
		margin: 0;
		min-width: 780px;
		padding: 0;

		li {
			margin: 0;
			padding: 0 0 14px 0;
		}
	}

	.header {
		display: flex;
		align-items: center;

		input {
			margin-top: -4px;
		}

		h4 {
			margin: 5px 10px;
			font-weight: normal;
			flex: 1;
			cursor: pointer;
		}

		.required {
			font-size: 13px;
			border: 1px solid var(--bl-error);
			color: var(--bl-error);
			border-radius: var(--bl-border-radius);
			padding: 2px 5px;
			font-weight: 500;
		}

		.default {
			font-size: 13px;
			border: 1px solid var(--bl-legend);
			color: var(--bl-legend);
			border-radius: var(--bl-border-radius);
			padding: 2px 5px;

			b {
				font-weight: 500;
			}
		}
	}

	p {
		color: var(--bl-legend);
		margin: 0;
		padding: 0;
		font-size: 12px;
	}
</style>