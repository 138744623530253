<template>
	<div style="display: flex; height: 100%; max-height: calc(100% - 61px);">
		<div class="bl-card bl-light-scroll" style="width: 250px; min-width: 250px; overflow: auto; margin-right: 0;">
			<div v-bl-input>
				<input type="text" ref="field" v-model="search" @keyup="updateSearch()" />
				<span class="suffix material-icons" style="display: block;">search</span>
			</div>
			<b>
				<i class="material-icons">public</i>
				Public ({{ models.public.length }})
			</b>
			<ul>
				<li :class="{selected: selected == model}" @click="select(model)" v-for="model in models.public" :key="model.id" :style="{display: model.show === false ? 'none' : null}">{{ model.label }}</li>
			</ul>
			<b>
				<i class="material-icons">shield</i>
				Internals ({{ models.internals.length }})
			</b>
			<ul>
				<li :class="{selected: selected == model}" @click="select(model)" v-for="model in models.internals" :key="model.id" :style="{display: model.show === false ? 'none' : null}">{{ model.label }}</li>
			</ul>
			<b>
				<i class="material-icons">history</i>
				History ({{ models.history.length }})
			</b>
			<ul>
				<li :class="{selected: selected == model}" @click="select(model)" v-for="model in models.history" :key="model.id" :style="{display: model.show === false ? 'none' : null}">{{ model.label }}</li>
			</ul>
			<b>
				<i class="material-icons">handyman</i>
				Maintenance
			</b>
			<ul>
				<li @click="maintenance('synchronize-sequences')">Sequence synchronization</li>
				<li @click="maintenance('vacuum')">Vacuum</li>
				<li @click="maintenance('reindex')">Reindex</li>
			</ul>
		</div>
		<div style="flex: 1; display: flex; flex-direction: column; max-width: calc(100% - 267px);" v-if="selected">
			<div class="bl-card">
				<div style="display: flex; align-items: center;">
					<h1 style="flex: 1;">
						<i class="material-icons">table_chart</i>
						{{ selected.name }} ({{ data ? data.total : 0 }} rows)
					</h1>
					<div v-bl-input style="width: 50px; margin-right: 20px;">
						<label>Limit</label>
						<input type="number" min="1" step="1" v-model="limit" required @change="loadData()" />
					</div>
					<div v-bl-input style="width: 50px; margin-right: 16px;">
						<label>Offset</label>
						<input type="number" min="0" step="1" v-model="offset" required @change="loadData()" />
					</div>
				</div>
				<div style="display: flex; align-items: center;">
					<div style="flex: 1"><BlFormFieldRichtext ref="filterRichText" :field="filterField" v-if="showFilterField" /></div>
					<button class="bl-icon-button" style="margin-bottom: -12px;" @click="loadData()">refresh</button>
				</div>
			</div>
			<div class="bl-light-scroll tableContainer">
				<table v-if="data" class="bl-datatable">
					<thead>
						<tr>
							<th v-for="field in data.metadata" :key="field.name" @click="setSort(field.name)">
								<div>
									<button class="bl-icon-button" @click="columnFilter($event, field)" style="font-size: 16px; padding: 6px 6px 6px 0;" v-bl-tooltip="'Quick filter'">filter_alt</button>
									<div>
										{{ field.name }}<br />
										<em>
											<i class="material-icons" v-if="field.pk">key</i>
											{{ field.type }}
										</em>
									</div>
									<span>
										<i class="material-icons sortArrow" v-if="sort == field.name || sort == ('-' + field.name)" :class="{reversed: sort != field.name}">arrow_upward</i>
									</span>
								</div>
							</th>
							<th v-if="selectedLine" style="width: 20px;"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in data.data" :key="item[pkName]" :class="{selected: item == selectedLine, newLine: item == newLine}">
							<td @click="editLine(item, field, $event)" v-for="field in data.metadata" :key="field.name" :class="'field' + field.name">
								<div v-if="item == selectedLine">
									<div v-bl-input v-if="field.type != 'boolean' && (!field.auto || item[field.name])">
										<input :type="['double precision', 'integer'].includes(field.type) ? 'number' : 'text'" :change="item[field.name] = (item[field.name] || item[field.name] === 0) ? item[field.name] : null" @keyup.enter="saveLine()" v-model="item[field.name]" />
									</div>
									<div v-if="field.type == 'boolean'" class="bl-toggle" style="width: 90px;">
										<div @click="item[field.name] = true" :class="{active: item[field.name] == true || item[field.name] == 'true'}" v-bl-tooltip="'True'">
											<span>T</span>
										</div>
										<div @click="item[field.name] = false" :class="{active: item[field.name] == false || item[field.name] == 'false'}" v-bl-tooltip="'False'">
											<span>F</span>
										</div>
										<div @click="item[field.name] = null" :class="{active: item[field.name] == null || item[field.name] == 'null'}" v-bl-tooltip="'Null'">
											<span>N</span>
										</div>
									</div>
								</div>
								{{ item == selectedLine ? '' : item[field.name] }}
							</td>
							<td v-if="selectedLine">
								<div v-if="item == selectedLine" class="actions">
									<button class="bl-icon-button" v-bl-tooltip="'Save'" @click="saveLine()">save</button>
									<button v-if="item != newLine" class="bl-icon-button" v-bl-tooltip="'Delete'" @click="deleteLine()">delete</button>
									<button @click="loadData();" class="bl-icon-button" v-bl-tooltip="'Cancel'">cancel</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<BlLogViewer channel="databasemanager" />
		</div>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { Variables, Snackbar, Dialog, EventEmitter } from 'InterfaceBundle'

export default {
	name: 'BlDatabaseManager',
	data() {
		return {
			/**
			 * List of models
			 * @type {Object}
			 */
			models: {
				public: [],
				internals: [],
				history: []
			},
			/**
			 * Selected table
			 * @type {Object}
			 */
			selected: null,
			/**
			 * Current edit line
			 * @type {Object}
			 */
			selectedLine: null,
			/**
			 * Table data (list and meta)
			 * @type {Object}
			 */
			data: null,
			/**
			 * Primary key field name for selected table
			 * @type {string}
			 */
			pkName: null,
			/**
			 * Sort column data
			 * @type {string}
			 */
			sort: null,
			/**
			 * Offset data
			 * @type {Number}
			 */
			offset: 0,
			/**
			 * Filter SQL
			 * @type {string}
			 */
			filter: null,
			/**
			 * Limit data
			 * @type {Number}
			 */
			limit: 20,
			/**
			 * Emulate formField to use richText field
			 * @type {Object}
			 */
			filterField: {
				value: '',
				isErrored: () => false,
				getTouched: () => false,
				setTouched: () => {},//this.loadData(),
				setValue: v => {
					this.filter = v
					this.filterField.value = v
				},
				label: 'Filters (SQL)',
				emitter: {
					change: new EventEmitter(),
					focus: new EventEmitter(),
					beforeSubmit: new EventEmitter(),
					beforeValidation: new EventEmitter(),
					dataChange: new EventEmitter()
				},
				options: {
					code: {
						mode: 'sql',
						height: 2,
						native: {showLineNumbers: false, showGutter: false, showPrintMargin: false}
					}
				}
			},
			showFilterField: false,
			search: '',
			newLine: null
		}
	},
	methods: {
		/**
		 * Edit line
		 * @param  {Object} line
		 */
		editLine(line, field, event) {
			this.pkSelected = {}
			if(this.pkName) this.pkSelected[this.pkName] = line[this.pkName]
			else {
				for(let field of this.data.metadata.filter(f => f.pk)) this.pkSelected[field.name] = line[field.name]
			}
			this.selectedLine = line
			if(field.type != 'boolean') this.$nextTick(() => event.composedPath()[1].querySelector('.field' + field.name + ' input').select())
		},
		/**
		 * Select table
		 * @param  {Object} model
		 */
		select(model) {
			this.selected = model
			this.sort = null
			this.data = null
			this.filter = null
			this.loadData()
			this.showFilterField = false
			this.filterField.setValue('')
			this.$nextTick(() => this.showFilterField = true)
		},
		/**
		 * Load current table data
		 */
		loadData() {
			this.selectedLine = null
			let request = {
				table: this.selected.name,
				offset: this.offset,
				limit: this.limit
			}
			if(this.sort) request.sort = this.sort
			if(this.filter) request.filter = this.filter
			Api.get('database-manager/', request).then(resp => {
				this.data = resp
				if(!this.sort) {
					this.sort = this.data.metadata.filter(f => f.pk)[0].name
					this.pkName = this.data.metadata.filter(f => f.auto)
					this.pkName = this.pkName.length ? this.pkName[0].name : null
				}
				this.newLine = {}
				if(resp.metadata.filter(m => m.name == 'archived' && m.type == 'boolean').length) this.newLine.archived = false
				this.data.data.push(this.newLine)
			})
		},
		/**
		 * Sort data
		 * @param {string} field
		 */
		setSort(field) {
			if(this.sort == field) this.sort = '-' + field
			else this.sort = field
			this.loadData()
		},
		/**
		 * Save column (insert and update)
		 */
		saveLine() {
			Api.put('database-manager/', {
				table: this.selected.name,
				data: this.selectedLine,
				where: this.pkSelected
			}).then(() => this.loadData())
		},
		/**
		 * Delete line
		 */
		deleteLine() {
			Dialog.confirm({
				title: 'Delete row ?',
				accept: 'Delete'
			}).then(() => {
				Api.delete('database-manager/', {
					table: this.selected.name,
					where: JSON.stringify(this.pkSelected)
				}).then(() => this.loadData())
			})
		},
		/**
		 * Execute maintenance operation
		 * @param  {string} operation
		 */
		maintenance(operation) {
			Variables.setGlobalLoading()
			Api.get('database-manager/' + operation + '/').then(() => {
				Snackbar.open({text: 'Operation done'})
				Variables.setGlobalLoading(false)
			})
		},
		/**
		 * Update search
		 */
		updateSearch() {
			for(let namespace in this.models) {
				for(let model of this.models[namespace]) model.show = this.search == '' || model.name.toLowerCase().includes(this.search.toLowerCase())
			}
		},
		/**
		 * Preformat filter on column
		 * @param  {object} event
		 * @param  {object} column
		 */
		columnFilter(event, column) {
			event.stopPropagation()
			let previousValue = this.filterField.value
			if(previousValue) previousValue = previousValue + ' AND '
			this.filterField.setValue(previousValue + column.name + ' = ')
			this.$refs.filterRichText.editor.setValue(this.filterField.value)
			this.filterField.emitter.change.emit()
			this.filterField.emitter.focus.emit()
			this.$refs.filterRichText.editor.clearSelection()
		}
	},
	created() {
		Api.get('database-manager/tables/').then(resp => this.models = resp)
	}
}
</script>

<style scoped lang="scss">
	.actions {
		max-height: 22px;

		.bl-icon-button {
			display: inline-block;
			padding: 0;
			font-size: 22px;
			margin-left: 7px;
		}
	}

	.bl-card > b {
		font-weight: 500;
		padding: 4px 6px;
		margin-top: 10px;
		display: block;
		display: flex;
		align-items: center;
		color: var(--bl-legend);

		i.material-icons {
			font-size: 20px;
			color: var(--bl-legend);
			margin-right: 3px;
			margin-top: -2px;
		}
	}

	.tableContainer {
		overflow: auto;
		margin: 5px 5px 0 5px;
		border: 1px solid var(--bl-border);
		border-radius: var(--bl-border-radius);
	}

	table.bl-datatable {
		width: 100%;
		margin: 0;

		td, th {
			border-radius: 0 !important;
		}
	}

	table thead tr th {
		position: sticky;
		top: 0;
		z-index: 2;
	}

	table thead tr th > div {
		display: flex;

		div {
			flex: 1;
		}
	}

	table tbody {
		tr {
			height: 46px;

			td {
				max-width: 200px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		tr.selected td {
			padding: 2px 5px 5px 5px;
			background-color: var(--bl-background);

			input {
				background-color: transparent;
			}
		}

		tr.newLine:not(.selected) td {
			opacity: 0;
		}
	}

	.sortArrow {
		transition: transform .2s;
		font-size: 18px;
		margin-top: 5px;
	}

	.sortArrow.reversed {
		transform: rotate(180deg);
	}

	ul {
		list-style: none;
		margin: 0 0 0 12px;
		padding: 0;

		li {
			padding: 4px 6px;
			cursor: pointer;
			transition: all .2s;
			border-radius: var(--bl-border-radius);
		}

		li:hover {
			background-color: var(--bl-background);
		}

		li.selected {
			color: var(--bl-primary);
			font-weight: 500;
			background-color: var(--bl-background);
		}
	}

	em {
		color: var(--bl-legend);
		font-size: 12px;
		display: flex;
		align-items: center;

		i.material-icons {
			font-size: 18px;
			margin-right: 4px;
			margin-top: -2px;
			margin-bottom: -3px;
		}
	}

	h1 {
		display: flex;
		align-items: center;

		.material-icons {
			color: var(--bl-legend);
			font-size: 20px;
			margin-right: 6px;
		}
	}
</style>