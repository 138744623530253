<template>
	<div class="selectContainer">
		<div class="header">
			<button class="bl-icon-button" @click="s.closeMobileDialog()">arrow_back</button>
			<input ref="searchInput" type="text" :placeholder="s.label" v-model="search" @input="setSearch()" @keydown="s.handleKeydown($event)" :disabled="s.getDisabled()" autoCapitalize="none">
		</div>
		<div class="items">
			<div v-if="s.loading" class="bl-loader-line"></div>
			<RecycleScroller ref="recycleScroller" class="dropDown bl-light-scroll" :items="s.arrayData" :item-size="s.itemSize * 1.1" key-field="key" v-slot="{ item }">
				<li :class="{active: s.isActive(item.value)}" @click="s.setValue(item.value)" v-html="item.label"></li>
			</RecycleScroller>
			<li @click="$refs.searchInput.focus()" v-if="s.emptyResults() && !s.isAutocomplete" class="emptyDataSet">{{ $t(s.loading ? 'interface.selectLoading' : 'interface.selectNoResult') }}</li>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BlSelectMobile',
	props: ['s'],
	data() {
		return {
			search: ''
		}
	},
	methods: {
		setSearch() {
			let s = this.s
			s.search = this.search
		}
	},
	mounted() {
		if(this.s.autocomplete && this.s.search) this.search = this.s.search
		this.$refs.searchInput.focus()
	}
}
</script>

<style scoped lang="scss">
	.selectContainer {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.header {
		display: flex;
		border-bottom: 1px solid var(--bl-border);
		overflow: hidden;

		input {
			flex: 1;
			border: 0;
			outline: none;
			font-size: 16px;
			margin-left: 5px;
			margin-bottom: -2px;
		}
	}

	.items {
		flex: 1;
		max-height: calc(100% - 50px);

		.dropDown {
			max-height: 100%;
		}
	}

	.dropDown {
		list-style-type: none;
		overflow: auto;

		li {
			padding: 15px 8px;
			cursor: pointer;
			transition: background-color .2s;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			min-height: 17px;
			user-select: none;
		}

		li:hover, li.hovered {
			background-color: var(--bl-background);
		}

		li.active {
			color: var(--bl-primary);
		}
	}

	.emptyDataSet {
		background-color: var(--bl-surface);
		list-style-type: none;
		color: var(--bl-legend);
		font-style: italic;
		cursor: default;
		user-select: none;
		padding: 10px 8px;
	}
</style>