<template>
	<div ref="holder">
		<div ref="calendarContainer" class="calendar bl-card" :style="{height: getHeight(), marginTop: getMarginTop()}" v-if="opened" :class="{range: range}">
			<div>
				<div class="header">
					<h4>{{ getHeader() }}</h4>
					<span class="bl-icon-button yearToggle" :class="{active: displayYear}" @click="toggleDisplayYear()">arrow_drop_down</span>
					<div style="flex: 1 1 0%;"></div>
					<span class="bl-icon-button" @click="changeMonth(-1)">arrow_left</span>
					<span class="bl-icon-button" @click="changeMonth(1)">arrow_right</span>
				</div>
				<div v-if="displayYear" class="yearSelector bl-light-scroll">
					<div ref="yearSelector">
						<div v-for="year in years" :key="year" :class="{active: currentMonth.getFullYear() == year}" @click="setYear(year)">{{ year }}</div>
					</div>
				</div>
				<table @wheel="handleScroll($event)" :class="{animateRight: getAnimate('right'), animateLeft: getAnimate('left')}" v-else>
					<thead>
						<tr>
							<th v-for="day in allDays" :key="day">{{ day }}</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(week, mIndex) in getData()" :key="mIndex">
							<td v-for="(day, dIndex) in week" :key="dIndex" :class="{disabled: day.disabled, inRange: day.inRange, today: day.today && !day.selected}" @click="selectDate(day)" @mouseover="hoverRange(day)">
								{{ day.day }}
								<div v-if="day.selected" class="selected" :class="{'selected-1': day.selected1, 'selected-2': day.selected2}">{{ day.day }}</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div ref="calendarSlotContainer" style="position: absolute;" :style="{marginTop: getMarginTop(5)}"><slot></slot></div>
</template>

<script>
import { DateFormat, Variables } from 'InterfaceBundle'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export default {
	name: 'BlCalendarInput',
	props: ['value', 'format', 'opened', 'range'],
	emits: ['change', 'openedChange', 'heightChange'],
	data() {
		return {
			data: [],
			currentMonth: null,
			currentMonthLabel: null,
			scrollTimeout: null,
			animationTimeout: null,
			allDays: DateFormat.dayShort,
			objValue: null,
			animate: null,
			position: 'bottom',
			displayYear: false,
			years: []
		}
	},
	watch: {
		opened(opened) {
			if(opened) {
				this.initialize()
				setTimeout(() => {
					window.addEventListener('click', this.handleBodyClick)
					window.addEventListener('focusin', this.handleBodyClick)
				}, 100)
			}
			else this.removeEventListeners()
		},
		value() {
			this.initialize()
		}
	},
	methods: {
		toggleDisplayYear() {
			this.displayYear = !this.displayYear
			if(this.displayYear) {
				if(!this.years.length) {
					const currentYear = new Date().getFullYear()
					for(let i = currentYear - 50; i < currentYear + 52; i++) this.years.push(i)
				}
				this.$nextTick(() => this.$refs.yearSelector.querySelector('.active').scrollIntoView({block: 'center'}))
			}
		},
		setYear(year) {
			setTimeout(() => {//Settimeout required to handle body click detection properly
				const diff = (year - this.currentMonth.getFullYear()) * 12
				this.changeMonth(diff)
				this.toggleDisplayYear()
			})
		},
		hoverRange(day) {
			if(this.range && this.objValue && this.objValue[0] && !this.objValue[1]) {
				for(let row of this.data) {
					for(let item of row) {
						item.inRange = item.date > this.objValue[0] && item.date < day.date
					}
				}
			}
		},
		getHeader() {
			return this.currentMonthLabel + ' ' + this.currentMonth.getFullYear()
		},
		getData() {
			return this.data
		},
		generateTable() {
			this.data = this.generateDataTable(this.currentMonth)
			this.currentMonthLabel = DateFormat.getMonthName(this.currentMonth)
			this.$emit('heightChange', this.getHeight(false))
		},
		generateDataTable(currentMonth) {
			const today = new Date()
			let data = []
			let start = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1)
			start.setDate(start.getDate() - DateFormat.getLocalDay(start))
			let end = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0)
			end.setDate(end.getDate() + 6 - DateFormat.getLocalDay(end))
			while(start <= end) {
				if(DateFormat.getLocalDay(start) == 0) data.push([])
				let line = {
					date: new Date(start),
					disabled: start.getMonth() != currentMonth.getMonth(),
					day: start.getDate(),
					today: this.isEqual(start, today)
				}
				if(this.range && this.objValue) {
					if(this.objValue[0] && this.isEqual(this.objValue[0], line.date)) {
						line.selected = true
						line.selected1 = true
					}
					if(this.objValue[1] && this.isEqual(this.objValue[1], line.date)) {
						line.selected = true
						line.selected2 = true
					}
					if(this.objValue[0] && this.objValue[1] && line.date > this.objValue[0] && line.date < this.objValue[1]) line.inRange = true
				}
				else if(!this.range && this.objValue && this.isEqual(this.objValue, line.date)) line.selected = true
				data[data.length - 1].push(line)
				start.setDate(start.getDate() + 1)
			}
			return data
		},
		isEqual(date1, date2) {
			return date1.toDateString() == date2.toDateString()
		},
		changeMonth(way) {
			let date = dayjs(this.currentMonth)
			if(way > 0) date = date.add(way, 'month')
			else if(way < 0) date = date.subtract(Math.abs(way), 'month')
			this.currentMonth = date.$d

			this.generateTable()
			this.animate = way < 0 ? 'left' : 'right'
			if(this.animationTimeout) clearTimeout(this.animationTimeout)
			this.animationTimeout = setTimeout(() => this.animate = null, 200)
		},
		handleScroll(event) {
			event.stopPropagation()
			event.preventDefault()
			if(!this.scrollTimeout) {
				if(event.deltaY > 20) this.changeMonth(1)
				else if(event.deltaY < -20) this.changeMonth(-1)
				this.scrollTimeout = setTimeout(() => this.scrollTimeout = null, 100)
			}
		},
		selectDate(date) {
			if(!date.disabled) {
				let data = this.data
				let realDate = null
				for(let item of data) {
					for(let item2 of item) {
						delete item2.selected
						if(item2.date.getTime() == date.date.getTime()) realDate = item2
					}
				}
				realDate.selected = true
				if(this.range) {
					let value = new Date(date.date)
					if(!this.objValue) this.objValue = []
					let firstPick = this.objValue[0] && value >= this.objValue[0] ? false : true
					if(this.objValue[1]) {
						this.objValue = []
						firstPick = true
					}

					if(firstPick) this.objValue = [value]
					else this.objValue[1] = value
					if(this.objValue[1]) {
						if(this.format) this.$emit('change', [dayjs(this.objValue[0]).format(DateFormat.transformPHPFormatToJS(this.format)), dayjs(this.objValue[1]).format(DateFormat.transformPHPFormatToJS(this.format))])
						else this.$emit('change', this.objValue)
						this.$emit('openedChange', false)
					}
					this.generateTable()
				}
				else {
					if(this.format) this.$emit('change', dayjs(date.date).format(DateFormat.transformPHPFormatToJS(this.format)))
					else this.$emit('change', date.date)
					this.objValue = new Date(date.date)
				}
			}
		},
		handleBodyClick(event) {
			const target = event.composedPath()[0]
			if(event.type == 'focusin' && target.tagName == 'DIALOG') return
			if(!this.$refs.calendarContainer?.contains(target) && !this.$refs.calendarSlotContainer?.contains(target)) {
				if(this.range && this.objValue && this.objValue.length == 1) this.$emit('change', null)
				this.$emit('openedChange', false)
				if(Variables.mobile) event.stopPropagation()
			}
		},
		removeEventListeners() {
			window.removeEventListener('click', this.handleBodyClick)
			window.removeEventListener('focusin', this.handleBodyClick)
			if(this.$refs.calendarContainer && this.$refs.holder) this.$refs.holder.appendChild(this.$refs.calendarContainer)
		},
		initialize() {
			this.position = 'bottom'
			if(this.range) {
				this.animate = {}
				if(this.value) {
					if(this.format) this.objValue = [
						dayjs(this.value[0], DateFormat.transformPHPFormatToJS(this.format)).toDate(),
						dayjs(this.value[1], DateFormat.transformPHPFormatToJS(this.format)).toDate()
					]
					else this.objValue = this.value
					this.currentMonth = new Date(this.objValue[0])
				}
				else {
					this.objValue = null
					this.currentMonth = new Date()
				}
			}
			else {
				if(this.value) {
					if(this.format) this.objValue = dayjs(this.value, DateFormat.transformPHPFormatToJS(this.format)).toDate()
					else this.objValue = this.value
					if(new Date(this.objValue).toString() === 'Invalid Date') this.objValue = new Date()
					this.currentMonth = new Date(this.objValue)
				}
				else this.currentMonth = new Date()
			}
			this.$nextTick(() => this.setPosition())
			this.generateTable()
			if(this.opened) {
				if(this.$refs.holder) {
					let position = this.$refs.holder.getBoundingClientRect()
					this.$nextTick(() => {
						document.body.appendChild(this.$refs.calendarContainer)
						this.$refs.calendarContainer.style.left = position.x + 'px'
						this.$refs.calendarContainer.style.top = position.y + 'px'
					})
				}
			}
		},
		getHeight(withUnits = true) {
			let length = this.data.length
			return ((length * 36) + 80) + (withUnits ? 'px' : 0)
		},
		getMarginTop(offset = 0) {
			if(this.position == 'bottom') return null
			return (this.getHeight(false) + 50 + offset) * -1 + 'px'
		},
		getAnimate(type) {
			return this.animate == type
		},
		setPosition() {
			const container = this.$refs.calendarContainer
			if(!container) return
			let parent = container.parentNode
			while(parent.parentNode) {
				if(parent == document.body || window.getComputedStyle(parent).overflow != 'visible') break
				parent = parent.parentNode
			}
			const parentHeight = parent.offsetHeight - 160
			const containerBottom = container.getBoundingClientRect().bottom
			this.position = containerBottom > parentHeight ? 'top' : 'bottom'
		}
	},
	created() {
		this.initialize()
	},
	unmounted() {
		this.removeEventListeners()
	}
}
</script>

<style scoped lang="scss">
	.calendar.range .selected-1:not(.selected-2) {
		width: 38px;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		height: 23px;
		padding-top: 6px;
		margin-top: -23px;
	}

	.calendar.range .selected-2:not(.selected-1) {
		width: 38px;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		height: 23px;
		padding-top: 6px;
		margin-top: -23px;
	}

	.calendar.range .selected-2.selected-1 {
		width: 38px;
		height: 23px;
		padding-top: 6px;
		margin-top: -23px;
	}

	.calendar {
		position: absolute;
		top: 38px;
		z-index: 100000;
		width: 270px;
		margin-left: 0;
		border: 0;
		box-shadow: 0 0 0 1px var(--bl-border);
		animation: bl-calendar-animatein 0.08s cubic-bezier(0.390, 0.575, 0.565, 1.000);
		overflow: hidden;
		transition: height .2s;

		.header {
			display: flex;
			align-items: center;
			border-bottom: 1px solid var(--bl-border);
			margin-left: -5px;
			width: calc(100% + 10px);
			margin-top: -5px;

			h4 {
				font-weight: bold;
				color: var(--bl-legend);
				font-size: 12px;
				margin: 0;
				padding: 0;
				padding-left: 10px;
			}

			span {
				cursor: pointer;
				padding: 5px;
				color: var(--bl-legend);
			}
		}

		table {
			width: 268px;
			user-select: none;
			border-collapse: collapse;

			tr {
				height: 38px;
			}

			thead tr th {
				color: var(--bl-legend);
				text-align: center;
				font-weight: normal;
				font-size: 12px;
				width: 38px;
			}

			tbody tr td {
				text-align: center;
				width: 38px;
			}

			tbody tr td:not(.disabled) {
				cursor: pointer;
			}

			tbody tr td:not(.disabled):hover:before {
				content: ' ';
				width: 36px;
				height: 36px;
				pointer-events: none;
				margin-top: -10px;
				margin-left: 0px;
				display: block;
				position: absolute;
				background-color: var(--bl-primary);
				opacity: .2;
				border-radius: var(--bl-border-radius);
			}

			tbody tr td.disabled {
				color: var(--bl-border);
			}

			tbody tr td.today {
				border-radius: var(--bl-border-radius);
				box-shadow: inset 0px 0px 0px 1px var(--bl-border);
			}

			tbody tr td.inRange:after {
				content: ' ';
				width: 38px;
				height: 23px;
				border-top: 1px solid var(--bl-legend);
				border-bottom: 1px solid var(--bl-legend);
				display: block;
				position: absolute;
				margin-top: -21px;
				background-color: var(--bl-border);
				opacity: .2;
				pointer-events: none;
				animation: bl-calendar-inrange 0.08s cubic-bezier(0.390, 0.575, 0.565, 1.000);
			}

			tbody tr td .selected {
				width: 36px;
				height: 27px;
				pointer-events: none;
				color: var(--bl-on-primary);
				font-weight: bold;
				padding-top: 9px;
				display: block;
				position: absolute;
				background-color: var(--bl-primary);
				border-radius: var(--bl-border-radius);
				margin-top: -27px;
			}
		}
	}

	.yearToggle {
		transition: transform .2s;
	}

	.yearToggle.active {
		transform: rotate(180deg);
	}

	.animateRight {
		animation: bl-calendar-animateright .2s;
	}

	.yearSelector {
		overflow-y: scroll;
		max-height: 262px;
		margin-right: -5px;
		padding-left: 4px;

		> div {
			display: flex;
			align-items: space-around center;
			flex-wrap: wrap;

			> div {
				border: 1px solid var(--bl-surface);
				border-radius: var(--bl-border-radius);
				padding: 4px 20px;
				margin: 5px 7px;
				cursor: pointer;
				transition: background-color .2s;
			}

			> div:hover:not(.active) {
				background-color: var(--bl-background);
			}

			> div.active {
				background-color: var(--bl-primary);
				color: var(--bl-on-primary);
			}
		}
	}

	@keyframes bl-calendar-animateright {
		0% {
			transform: translateX(40px);
		}
		100% {
			transform: translateX(0);
		}
	}

	.animateLeft {
		animation: bl-calendar-animateleft .2s;
	}

	@keyframes bl-calendar-animateleft {
		0% {
			transform: translateX(-40px);
		}
		100% {
			transform: translateX(0);
		}
	}

	@keyframes bl-calendar-animatein {
		0% {
			transform: scaleY(0.5);
			transform-origin: 50% 0%;
		}
		100% {
			transform: scaleY(1);
			transform-origin: 50% 0%;
		}
	}

	@keyframes bl-calendar-inrange {
		0% {
			transform: scale(0.5);
		}
		100% {
			transform: scale(1);
		}
	}
</style>