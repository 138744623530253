<template>
	<div style="display: flex; height: 100%;">
		<div class="tree bl-card" v-if="data">
			<div style="text-align: center; margin-top: 10px;">
				<BlButton icon="security_update_good" :label="updateStatus" @click="save()" :disabled="actionBtnClicked" />
				<BlButton icon="sync" label="Cancel update" @click="cancel()" :disabled="actionBtnClicked" />
			</div>

			<BlTabs v-model="filterList">
				<div>Stage {{done.length+1 }}/ {{todo.length}}</div>
				<BlTab name="all">
					<BlTabHeader>
						<icon>list</icon>
						All changes
					</BlTabHeader>
				</BlTab>
				<BlTab name="diff">
					<BlTabHeader>
						<icon>report</icon>
						Only conflicts
					</BlTabHeader>
				</BlTab>
			</BlTabs>
			<ul v-for="(value,bundle) in data" :key="bundle">
				<h1>{{bundle}}</h1>
				<li v-for="group in value" :key="group.model">
					<span>
						<icon :style="{color: group.color}">{{ group.icon }}</icon>
						{{ group.model.split('.')[1] }}
					</span>
					<ul>
						<li v-for="(item, i) in group.items" :key="i" :style="{display: filterList == 'all' || item.diff ? null : 'none'}" :class="{diff: item.diff, checked: item.checked}">
							<input type="checkbox" class="bl-checkbox" v-model="item.checked" />
							<div style="width: 70px;">
								<span class="status" style="--status-color: #499f49;" v-if="item.type == 'create'">
									<icon>add</icon>
									Create
								</span>
								<span class="status" style="--status-color: #a90505;" v-if="item.type == 'delete'">
									<icon>delete</icon>
									Delete
								</span>
								<span class="status" style="--status-color: #d5a54e;" v-if="item.type == 'update'">
									<icon>upgrade</icon>
									Update
								</span>
							</div>
							<div @click="selectDiff(item)">{{ item.name }}</div>
							<button class="bl-icon-button" v-if="item.diff" @click="selectDiff(item)">expand_content</button>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<div class="bl-card" style="flex: 1; height: calc(100% - 7px); padding: 0; overflow: hidden;">
			<div ref="codeEditor" style="width: 100%; height: 100%;"></div>
		</div>
	</div>
</template>

<script>
import { Api } from 'ModelBundle'
import { MonacoLoader } from 'InstallBundle'
import { Variables, Dialog, ViewLoader } from 'InterfaceBundle'

export default {
	name: 'InstallPostInstall',
	data() {
		return {
			data: null,
			done: [],
			todo: [],
			selectedDiff: null,
			settings: null,
			filterList: 'all',
			actionBtnClicked: false
		}
	},
	props: ["type"],
	created() {
		MonacoLoader.loadEditor(() => {
			let req = {settings: {}}
			req.settings['context("settings"):script.run("internalInstallEditorGetSettings")'] = {
				data: 'local.settings'
			}

			Api.post('api/structure/', req).then(resp => {
				this.settings = resp.settings.data
				let options = this.settings?.editor ? this.settings.editor : {}
				if(!options.fontSize) options.fontSize = '12px'
				options.model = null
				if(!options.theme) options.theme = 'vs-' + Variables.theme
				options.automaticLayout = true
				this.editor = window.monaco.editor.createDiffEditor(this.$refs.codeEditor, options)
				Api.get(`/install-elements/post-${this.type}/`).then(resp => {
					this.data = resp.data
					this.done = resp.done
					this.todo = resp.todo
				})
			})
		})
	},
	unmounted(){
		clearInterval(this.updateInterval)
	},
	computed: {
		updateStatus: function() {
			return (this.done.length+1 === this.todo.length ? 'Finish update' : 'Update bundle')
		}
	},
	methods: {
		selectDiff(item) {
			if(!item.diff) return
			const diffModel = {
				original: window.monaco.editor.createModel(this.toText(item.diff[0]), 'text'),
				modified: window.monaco.editor.createModel(this.toText(item.diff[1]), 'text')
			}
			this.editor.setModel(diffModel)
		},
		toText(item) {
			let ret = []
			for(let key in item) {
				ret.push('-------------------- ' + key + ' --------------------')
				ret.push(item[key])
			}
			return ret.join("\n")
		},
		save() {
			Dialog.confirm({
				title: this.updateStatus+" ?",
				accept: "Update",
				cancel: 'Cancel'
			}).then(() => {
				this.actionBtnClicked = true
				Variables.setGlobalLoading(true)
				const req = {}
				for(let key of Object.keys(this.data)){
					let elements = this.data[key]
					elements = elements.map(group => {
						return {
							model: group.model,
							items: group.items.filter(i => i.checked).map(i => i.name)
						}
					})
					req[key] = elements
				}
				Api.post(`/install-elements/post-${this.type}/`, {data: JSON.stringify(req)}).then(() => {
					ViewLoader.update()
				})
			})
		},
		cancel() {
			this.actionBtnClicked = true
			Api.get('/install-elements/cancel-install/')
			this.updateInterval = setInterval(function(){
				Api.get('interface/manifest.json')
				if(Variables.upgrading) {
					clearTimeout(this.updateInterval)
					ViewLoader.update()
				}
			}, 1000);
		}
	}
}
</script>

<style scoped lang="scss">
	.tree {
		width: 350px;
		padding: 0;

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		> ul {
			padding: 5px 10px;

			> li {
				position: relative;

				> span {
					background-color: var(--bl-surface);
					z-index: 2;
					position: absolute;
					display: flex;
					align-items: center;
					padding-right: 5px;

					> icon {
						font-size: 18px;
						margin-right: 4px;
					}
				}
			}

			> li::before {
				content: ' ';
				border-top: 1px solid var(--bl-border);
				width: 100%;
				height: 1px;
				display: block;
				position: absolute;
				margin-top: 9px;
			}
		}

		> ul > li > ul {
			padding: 30px 10px 10px 10px;

			> li {
				display: flex;
				align-items: center;

				input {
					margin-top: -2px;
					margin-right: 10px;
				}

				span.status {
					display: inline-flex;
					align-items: center;
					border: 1px solid var(--status-color);
					border-radius: 4px;
					padding: 2px 4px;
					font-size: 12px;
					font-weight: 500;
					background-color: color-mix(in srgb, var(--status-color) 5%, var(--bl-surface));
					color: var(--status-color);

					> icon {
						color: var(--status-color);
						font-size: 14px;
					}
				}
			}

			> li:not(.checked) span.status {
				text-decoration: line-through;
			}

			> li.diff {
				> div {
					font-weight: 500;
					cursor: pointer;
				}

				> button {
					font-size: 18px;
					padding: 0;
					margin-left: 5px;
				}
			}
		}
	}
</style>